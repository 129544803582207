<template>
  <div class="order">
    <div class="title">
      <span @click="nav(0)" :class="{ active1: number == 0 }">所有订单</span>
      <el-divider direction="vertical"></el-divider>
      <span @click="nav(1)" :class="{ active1: number == 1 }">待付款</span>
      <el-divider direction="vertical"></el-divider>
      <span @click="nav(2)" :class="{ active1: number == 2 }">待发货</span>
      <el-divider direction="vertical"></el-divider>
      <span @click="nav(3)" :class="{ active1: number == 3 }">待收货</span>
      <el-divider direction="vertical"></el-divider>
      <span @click="nav(4)" :class="{ active1: number == 4 }">待评价</span>
    </div>
    <div class="order-list">
      <div style="height: 670px" v-if="list.length">
        <table class="order-table-head">
          <thead>
            <tr>
              <th style="width: 35%">宝贝</th>
              <th style="width: 15%">单价</th>
              <th style="width: 10%">数量</th>
              <th style="width: 15%">实付</th>
              <th style="width: 10%">交易状态</th>
              <th style="width: 20%">交易操作</th>
            </tr>
          </thead>
        </table>
        <div>
          <template v-if="searchData.m_type == 1">
            <table class="order-table-content" v-for="(item, index) in list" :key="index">
              <thead>
                <tr v-if='item.order_status_id == 1 || item.order_status_id == 11 || item.order_status_id == 19'>
                  <th colspan="1">订单号：{{ item.out_trade_no }}</th>
                  <th colspan="2" style="text-align:left;"> <span @click="$util.toShop(item.shop_type, item.shop_id )" class="hover">{{ item.shop_name }} </span></th>
                  <th colspan="2">{{ item.date_added }}</th>
                  <th style="text-align: right">
                    <i @click="del(item)" v-if="
                      item.order_status_id == 11 ||
                      item.order_status_id == 18 ||
                      item.order_status_id == 5 ||
                      item.order_status_id == 19
                    " class="el-icon-delete"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(order, idx) in (item.zorder || {}).data">
                  <tr :key="idx" class="first-row" v-if='item.order_status_id == 1 || item.order_status_id == 11 || item.order_status_id == 19'>
                    <td style="text-align:left;border-right:1px solid #eee;font-weight:bold;" colspan="3">
                      <span @click="$util.toShop(order.shop_type, order.shop_id )" class="hover">{{ order.shop_name }}</span>
                    </td>
                  </tr>
                  <template v-else>
                    <tr :key="idx+'_1'" style="background: #dedcdc;line-height:30px;font-weight:bold;text-align:left">
                      <td colspan="3" style="text-align: left">订单号：{{ order.out_request_no }}</td>
                      <td colspan="2" style="text-align: left">{{ item.date_added }}</td>
                      <td style="text-align: right">
                        <i @click="del(item)" v-if="item.order_status_id == 18 || item.order_status_id == 5" class="el-icon-delete"></i>
                      </td>
                    </tr>
                    <tr :key="idx+'_2'" class="first-row">
                      <td style="text-align:left;border-right:1px solid #eee;font-weight:bold;" colspan="3 ">
                        <span @click="$util.toShop(order.shop_type, order.shop_id )" class="hover">{{ order.shop_name }}</span>
                      </td>
                    </tr>
                  </template>
                  <tr v-for="(pro, i) in order.get_order_product" :key="idx + '-' + i">
                    <td style="width: 35%;">
                      <div class="item">
                        <div class="item-img-wraper">
                          <img @click="$util.toDetail(pro.shop_type, pro.product_id )" class="pro" :src="$config.baseUrl + pro.files_path" />
                        </div>
                        <div class="item-info">
                          <p class="item-name">{{ pro.name }}</p>
                          <p class="about">
                            {{ JSON.parse(pro.option || "{}").spec }}
                          </p>
                          <p class="about" v-if="order.isprice == 2">
                            <el-tag type="danger">议价</el-tag>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="item-price" style="width: 15%;">
                      <span class="price" v-if="pro.pisprice == 2">￥{{ pro.pmax_price }}</span>
                      <span v-else-if="pro.act_status == 1">
                        ￥{{ pro.act_price }}
                      </span>
                      <span class="price" v-else>￥{{parseFloat(pro.price / 100).toFixed(2)}}</span>
                    </td>
                    <td style="width: 10%;border-right:1px solid #eee">
                      <span class="quantity">{{ pro.quantity }}</span>
                    </td>
                    <template v-if=" item.order_status_id == 1 || item.order_status_id == 11 || item.order_status_id == 19">
                      <template v-if="idx == 0 && i == 0">
                        <td style="width: 15%; " rowspan="99">
                          ￥
                          <b>
                            {{ parseFloat(item.total / 100).toFixed(2) }}</b>
                        </td>
                        <td style="width: 10%; line-height: 25px;" rowspan="99">

                          <p v-if="item.order_status_id == 1">待付款</p>
                          <p v-if="item.order_status_id == 11" class="hover" @click="refund( item.order_id)">退款成功 <span v-if="item.isstrange == 2" style="color:#fc8752">(异常)</span></p>
                          <p v-if="item.order_status_id == 19">已取消</p>

                          <p class="hover" @click="
                            $router.push({
                              path: '/member-order-detail',
                              query: { order_master_id: item.order_id },
                            })
                          ">
                            订单详情
                          </p>

                          <p v-if="order.order_status_id != 1&&!order.get_complain&&order.order_status_id != 7&&order.order_status_id != 19">
                            <span class="hover" @click="
                            toAddComplain(order.order_id)
                         
                        ">
                              投诉卖家
                            </span>
                          </p>

                          <template v-if='order.get_complain'>
                            <p v-if='order.get_complain.admin_status==0' @click="toComplain(order.get_complain.id)">
                              投诉中
                            </p>
                            <p v-else @click="toComplain(order.get_complain.id)">
                              投诉已完成
                            </p>
                          </template>

                        </td>
                        <td style="width: 15%;line-height:30px;border-right:1px solid #eee" rowspan="99">
                          <el-button type="info" shape="circle" small v-if="item.order_status_id == 1" @click="send(item)">
                            付款
                          </el-button>
                          <p class="hover" v-if="item.order_status_id == 1" @click="cancel(item)">
                            取消订单
                          </p>
                          <p class="  hover" v-if="(item.order_status_id == 11||item.order_status_id == 18||item.order_status_id == 19||item.order_status_id == 5||item.order_status_id == 6)"
                            @click="toBuy(item.order_id)">
                            再次购买
                          </p>
                          <p v-if="item.order_status_id != 1&&!item.get_complain&&item.order_status_id != 7&&item.order_status_id != 19">
                            <span class="hover" @click="
                          $router.push({
                            path: '/member-order-complaint',
                            query: {
                              order_id: order.order_id,
                            },
                          })
                        ">
                              投诉卖家
                            </span>
                          </p>
                        </td>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="i == 0">
                        <td style="width: 15%;line-height:25px" :rowspan="order.get_order_product.length">
                          ￥

                          <b>
                            {{ parseFloat(order.total / 100).toFixed(2) }}</b>
                        </td>
                        <td style="width: 10%; line-height: 25px" :rowspan="order.get_order_product.length">

                          <p v-if="order.order_status_id == 13&&!(order.getrefund)">已付款</p>
                          <div class="zt" @click="refund((order.getrefund||{}).zorder_id)">
                            <p v-if="
                            ((order.getrefund || {}).refund_state == 5||(order.getrefund || {}).seller_state == 3)&& (order.getrefund || {}).refund_type==1&& order.order_status_id != 5&&order.order_status_id!=6" 
                            >

                              <span class="hover"> 拒绝退款 </span>
                            </p>

                            <p v-if="(order.getrefund || {}).seller_state == 1&& (order.getrefund || {}).refund_type==1&&(order.getrefund || {}).refund_state != 4  ">
                              <span class="hover" style="color: red">
                                退款中
                              </span>
                            </p>
                            <p v-if="order.order_status_id == 3&& !order.getrefund ">已发货</p>
                            <p v-if="
                          ((order.getrefund || {}).seller_state == 5||(order.getrefund || {}).refund_state == 6)&&(order.getrefund || {}).refund_type==2&&!(order.getrefund || {}).express_id
                          ">
                              <span class="hover themefont"> 待退货 </span>
                            </p>
                            <p v-if="((order.getrefund || {}).seller_state == 1&& (order.getrefund || {}).refund_type==2 )">
                              <span class="hover" style="color: red">
                                退货退款中
                              </span>
                            </p>
                            <p v-if="(order.getrefund || {}).refund_state == 4 " class="hover">
                              退款成功<span v-if="order.isstrange == 2" style="color:#fc8752">(异常)</span> </p>

                            <p v-if="(order.getrefund || {}).express_id&&(order.getrefund || {}).seller_state==5">
                              <span class="hover themefont"> 退货待签收</span>
                            </p>

                            <p v-if="(order.getrefund || {}).seller_state == 3&&(order.getrefund || {}).refund_type==2&&order.order_status_id != 5&&order.order_status_id != 6">
                              <span class="hover themefont"> 拒绝退货 </span>
                            </p>
                            <p v-if="(order.order_status_id == 5||order.order_status_id == 6)">交易成功</p>

                            <p v-if="(order.getrefund || {}).seller_state == 7&& (order.getrefund || {}).refund_type==2&&!(order.order_status_id == 5||order.order_status_id == 6)">
                              <span class="hover themefont"> 退货拒退款
                              </span>
                            </p>
                          </div>
                          <p class="hover" @click="
                            $router.push({
                              path: '/member-order-detail',
                              query: { order_id: order.order_id },
                            })
                          ">
                            订单详情
                          </p>
                          <p v-if="order.order_status_id == 19">
                            已取消
                          </p>
                          <p v-if="order.shipping_code" class=" btn hover" @click="logistics(order)">
                            查看物流
                          </p>

                        </td>
                        <td style="width: 20%;line-height:30px" :rowspan="order.get_order_product.length">
                          <el-button type="info" shape="circle" small v-if="order.order_status_id == 5" @click="evaluate(order.order_id, 1)">
                            评价
                          </el-button>

                          <p v-if="order.order_status_id == 6"> <span class=" hover" @click="evaluate(order.order_id, 0)">
                              查看评价
                            </span> </p>

                          <p v-if="
                       ( !order.getrefund && order.order_status_id == 13) &&!( (order.getrefund || {}).refund_state == 1 ||
                        (order.getrefund || {}).seller_state == 1)
                      ">
                            <span type="info" class="hover" small @click="refund(order.order_id)">
                              退款
                            </span>
                          </p>
                          <p v-if="
                          ( !order.getrefund && order.order_status_id == 3) &&!( (order.getrefund || {}).refund_state == 1 ||
                        (order.getrefund || {}).seller_state == 1)
                          ">
                            <span type="info" class="hover" small @click="refund(order.order_id)">
                              退货退款
                            </span>
                          </p>

                          <p>
                            <el-button type="info" shape="circle" small v-if="order.order_status_id == 3" @click="confirm(order)">
                              确认收货
                            </el-button>
                          </p>
                          <p class="  hover" v-if="(order.order_status_id == 11||order.order_status_id == 18||order.order_status_id == 19||order.order_status_id == 5||order.order_status_id == 6)"
                            @click="toBuy(item.order_id)">
                            再次购买
                          </p>
                          <p v-if="order.order_status_id != 1&&!order.get_complain&&order.order_status_id != 7&&order.order_status_id != 19">
                            <span class="hover" @click="
                          $router.push({
                            path: '/member-order-complaint',
                            query: {
                              order_id: order.order_id,
                            },
                          })
                        ">
                              投诉卖家
                            </span>
                          </p>
                          <p v-if='(order.get_complain||{}).admin_status==0'  >
                            投诉中
                          </p>

                        </td>
                      </template>
                    </template>
                  </tr>
                </template>
              </tbody>
            </table>
          </template>
          <template v-else>
            <table class="order-table-content" v-for="(order, index) in list" :key="index">
              <thead>
                <tr>
                  <th colspan="3">订单号：{{ order.out_request_no }}</th>
                  <th colspan="2">{{ order.date_added }}</th>
                  <!-- <th colspan="2"> <span
                      class="hover"
                      @click="toShop(order)"
                    > {{ order.shop_name }}</span></th> -->
                  <th style="text-align: right">
                    <i @click="del(order)" v-if="
                      order.order_status_id == 11 ||
                      order.order_status_id == 18 ||
                      order.order_status_id == 5 ||
                      order.order_status_id == 19
                    " class="el-icon-delete"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="first-row">
                  <td style="text-align:left;border-right:1px solid #eee;font-weight:bold;" colspan="3 ">
                    <span @click="$util.toShop(order.shop_type, order.shop_id )" class="hover">{{ order.shop_name }}</span>
                  </td>
                </tr>
                <tr v-for="(pro, i) in order.get_order_product" :key="index + '-' + i">
                  <td style="width: 35%">
                    <div class="item">
                      <div class="item-img-wraper">
                        <img @click="$util.toDetail(pro.shop_type, pro.product_id )" class="pro" :src="$config.baseUrl + pro.files_path" />
                      </div>
                      <div class="item-info">
                        <p class="item-name">{{ pro.name }}</p>
                        <p class="about">
                          {{ JSON.parse(pro.option || "{}").spec }}
                        </p>
                        <p class="about" v-if="order.isprice == 2">
                          <el-tag type="danger">议价</el-tag>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td class="item-price" style="width: 15%">
                    <span class="price" v-if="pro.pisprice == 2">￥{{ pro.pmax_price }}</span>
                    <span v-else-if="pro.act_status == 1">
                      ￥{{ pro.act_price }}
                    </span>
                    <span class="price" v-else>￥{{parseFloat(pro.price / 100).toFixed(2)}}</span>
                  </td>
                  <td style="width: 10%;border-right:1px solid #eee">
                    <span class="quantity">{{ pro.quantity }}</span>
                  </td>

                  <template v-if="
                      order.order_status_id == 1 || order.order_status_id == 11
                    ">
                    <template v-if="i == 0">
                      <td style="width: 15%;" rowspan="99">
                        ￥

                        <b>
                          {{ parseFloat(order.total / 100).toFixed(2) }}</b>
                      </td>
                      <td style="width: 10%; line-height: 25px;" rowspan="99">
                        <div style="color: #999">
                          <p v-if="order.order_status_id == 1">待付款</p>
                          <p v-if="order.order_status_id == 11">退款成功</p>
                          <p v-if="order.order_status_id ==19">已取消</p>
                        </div>
                        <p class="hover" @click="
                            $router.push({
                              path: '/member-order-detail',
                              query: { order_id: order.order_id },
                            })
                          ">
                          订单详情
                        </p>
                        <p v-if="order.order_status_id == 19">
                          已取消
                        </p>
                      </td>
                      <td style="width: 15%;line-height:30px;" rowspan="99">
                        <el-button type="info" shape="circle" small v-if="order.order_status_id == 1" @click="send(order)">
                          付款
                        </el-button>
                        <p class="hover" v-if="order.order_status_id == 1" @click="cancel(order)">
                          取消订单
                        </p>
                        <p class="  hover" v-if="(order.order_status_id == 11||order.order_status_id == 18||order.order_status_id == 19||order.order_status_id == 5||order.order_status_id == 6)"
                          @click="toBuy(item.order_id)">
                          再次购买
                        </p>
                        <p v-if="order.order_status_id != 1&&!order.get_complain&&order.order_status_id != 7&&order.order_status_id != 19">
                          <span class="hover" @click="
                         toAddComplain(order.order_id)
                        ">
                            投诉卖家
                          </span>
                        </p>
                      </td>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="i == 0">
                      <td style="width: 15%;line-height:25px;" rowspan="99">
                        ￥

                        <b>
                          {{ parseFloat(order.total / 100).toFixed(2) }}</b>
                      </td>
                      <td style="width: 10%; line-height: 25px; " rowspan="99">

                        <p v-if="order.order_status_id == 13&&!(order.getrefund)">已付款</p>

                        <div class="zt" @click="refund((order.getrefund||{}).zorder_id)">
                          <p v-if="
                            ((order.getrefund || {}).refund_state == 5||(order.getrefund || {}).seller_state == 3)&& (order.getrefund || {}).refund_type==1&& order.order_status_id != 5&&order.order_status_id!=6" 
                            >

                            <span class="hover"> 拒绝退款 </span>
                          </p>

                          <p v-if="(order.getrefund || {}).seller_state == 1&& (order.getrefund || {}).refund_type==1&&(order.getrefund || {}).refund_state != 4  ">
                            <span class="hover" style="color: red">
                              退款中
                            </span>
                          </p>
                          <p v-if="order.order_status_id == 3&& !order.getrefund ">已发货</p>
                          <p v-if="
                          ((order.getrefund || {}).seller_state == 5||(order.getrefund || {}).refund_state == 6)&&(order.getrefund || {}).refund_type==2&&!(order.getrefund || {}).express_id
                          ">
                            <span class="hover themefont"> 待退货 </span>
                          </p>
                          <p v-if="((order.getrefund || {}).seller_state == 1&& (order.getrefund || {}).refund_type==2 )">
                            <span class="hover" style="color: red">
                              退货退款中
                            </span>
                          </p>
                          <p v-if="(order.getrefund || {}).refund_state == 4 " class="hover">
                            退款成功<span v-if="order.isstrange == 2" style="color:#fc8752">(异常)</span> </p>

                          <p v-if="(order.getrefund || {}).express_id&&(order.getrefund || {}).seller_state==5">
                            <span class="hover themefont"> 退货待签收</span>
                          </p>

                          <p v-if="(order.getrefund || {}).seller_state == 3&&(order.getrefund || {}).refund_type==2&&order.order_status_id != 5&&order.order_status_id != 6">
                            <span class="hover themefont"> 拒绝退货 </span>
                          </p>
                          <p v-if="(order.order_status_id == 5||order.order_status_id == 6)">交易成功</p>

                          <p v-if="(order.getrefund || {}).seller_state == 7&& (order.getrefund || {}).refund_type==2&&!(order.order_status_id == 5||order.order_status_id == 6)">
                            <span class="hover themefont"> 退货拒退款
                            </span>
                          </p>
                        </div>
                        <p class="hover" @click="
                            $router.push({
                              path: '/member-order-detail',
                              query: { order_id: order.order_id },
                            })
                          ">
                          订单详情
                        </p>
                        <p v-if="order.order_status_id == 19">
                          已取消
                        </p>
                        <p v-if="order.shipping_code" class="  btn hover" @click="logistics(order)">
                          查看物流
                        </p>

                      </td>
                      <td style="width: 15%;line-height:30px; " rowspan="99">
                        <el-button type="info" shape="circle" small v-if="order.order_status_id == 5" @click="evaluate(order.order_id, 1)">
                          评价
                        </el-button>
                        <p v-if="order.order_status_id == 6"> <span class=" hover" @click="evaluate(order.order_id, 0)">
                            查看评价
                          </span> </p>
                        <p class="  hover" v-if="(order.order_status_id == 11||order.order_status_id == 18||order.order_status_id == 19||order.order_status_id == 5||order.order_status_id == 6)"
                          @click="toBuy(item.order_id)">
                          再次购买
                        </p>
                        <p v-if="
                       ( !order.getrefund && order.order_status_id == 13) &&!( (order.getrefund || {}).refund_state == 1 ||
                        (order.getrefund || {}).seller_state == 1)
                      ">
                          <span type="info" class="hover" small @click="refund(order.order_id)">
                            退款
                          </span>
                        </p>
                        <p v-if="
                          ( !order.getrefund && order.order_status_id == 3) &&!( (order.getrefund || {}).refund_state == 1 ||
                        (order.getrefund || {}).seller_state == 1)
                          ">
                          <span type="info" class="hover" small @click="refund(order.order_id)">
                            退货退款
                          </span>
                        </p>
                        <p>
                          <el-button type="info" shape="circle" small v-if="order.order_status_id == 3" @click="confirm(order)">
                            确认收货
                          </el-button>
                        </p>
                        <p v-if="order.order_status_id != 1&&!order.get_complain&&order.order_status_id != 7&&order.order_status_id != 19">
                          <span class="hover" @click="
                         toAddComplain(order.order_id)
                        ">
                            投诉卖家
                          </span>
                        </p>
                      </td>
                    </template>
                  </template>
                </tr>
              </tbody>
            </table>
          </template>
        </div>

        <el-pagination @current-change="getOrder()" :current-page.sync="searchData.page" :page-sizes="[10, 20, 30, 40]" :page-size.sync="searchData.limit" :total="total">
        </el-pagination>
      </div>
      <el-empty v-else description="暂无数据"></el-empty>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      visible_tips: false,

      dataitem: {},
      cancel_box: false,
      del_box: false,
      total: 0,
      last_page: 0,
      showSure: false,
      list: [],
      index: "",
      number: "0",
      order_id: undefined,
      allList: [],
      payingList: [],
      payList: [],
      sendList: [],
      receiveList: [],
      completeList: [],
      searchData: {
        member_id: this.$store.state.member.id,
        page: 1,
        limit: 10
      }
    };
  },
  watch: {
    // number() {
    //   this.nav(this.number);
    // }
  },
  mounted() {
    this.nav(0);
    if (this.$route.query.number) {
      this.number = this.$route.query.number;
      this.nav(this.number);
    }
  },
  methods: {
    toComplain(id) {
      this.$router.push({ path: "/member-complaint-edit", query: { id: id } });
    },

    // 添加到购物车
    toBuy(id) {
      this.$post("/home/buyAgain", {
        order_id: id
      }).then(() => {
        this.$router.push("/cart");
      });
    },
    //取消订单
    cancel(item) {
      let order = item.get_ordernum || item;
      this.$confirm("是否取消订单？", { type: "wraning" }).then(() => {
        this.$put("home/order/" + order.order_id, {
          isvoid: 1,
          m_type: 1
        }).then(() => {
          this.$message.success("已取消订单");
          this.getOrder();
        });
      });
    },
    //删除订单
    del(item) {
      this.$confirm("是否删除订单？", { type: "wraning" }).then(() => {
        this.$del("home/order/" + item.order_id, {
          m_type: item.get_ordernum ? "" : 1
        }).then(() => {
          this.$message.success("已删除订单");
          this.getOrder();
        });
      });
    },
    //订单添加
    send(item) {
      let order = item.get_ordernum || item;
      this.$put("home/order/" + order.order_id, {
        payment_type: order.payment_type
      }).then(res => {
        this.$router.push({
          path: "/payType",
          query: {
            order_master_id: order.order_id,
            totalMoney: (order.total / 100).toFixed(2)
          }
        });
      });
    },

    evaluate(id, num) {
      this.$router.push({
        path: "/member-order-evaluate",
        query: { id: id, num: num }
      });
    },

    //确认收货
    confirm(item) {
      this.$confirm("是否确定收货？", { type: "wraning" }).then(() => {
        this.$post("home/order/member/confirg", {
          order_id: item.order_id
        }).then(res => {
          this.getOrder();

          console.log("confirmid", res);
          this.showSure = false;
        });
      });
    },

    nav(index) {
      this.number = index;
      if (index == 0) {
        this.searchData = {
          member_id: this.$store.state.member.id,
          m_type: 1,
          page: 1,
          is_show: 0,
          limit: 8
        };
      }
      if (index == 1) {
        this.searchData = {
          member_id: this.$store.state.member.id,
          order_status_id: 1, //待付款
          m_type: 1,
          is_show: 0,
          page: 1,
          limit: 10
        };
      }
      if (index == 2) {
        this.searchData = {
          member_id: this.$store.state.member.id,
          order_status_id: 13, //待发货
          page: 1,
          is_show: 0,
          limit: 10
        };
      }
      if (index == 3) {
        this.searchData = {
          member_id: this.$store.state.member.id,
          order_status_id: 3, //待收货
          is_show: 0,
          page: 1,
          limit: 10
        };
      }
      if (index == 4) {
        this.searchData = {
          member_id: this.$store.state.member.id,
          order_status_id: 5, //待收货
          page: 1,
          invoid: 0,
          limit: 10
        };
      }

      this.getOrder();
    },
 toAddComplain(id) {
      const routeData = this.$router.resolve({ path: "/member-order-complaint", query: { order_id: id } });
      window.open(routeData.href, "_blank");
    },
    logistics(item) {
      this.url =
        "https://www.baidu.com/s?wd=" + item.shipping_name + item.shipping_code;

      window.open(this.url);
    },
    refund(id) {
      const routeData = this.$router.resolve({
        path: "/member-order-refund",
        query: { id: id }
      });

      window.open(routeData.href, "_blank");
    },
    //查询订单
    getOrder() {
      //全部
      return this.$get("home/order", this.searchData).then(res => {
        let arr = res.items.data || [];
        arr.forEach(item => {
          this.$set(item, "moreShow", false);
        });
        // this.list = arr.forEach(i => {
        //   if (i.get_orderz) {
        //     i.get_order_product = i.get_orderz;
        //   }
        // });

        this.list = arr;
        this.total = res.items.total;
        console.log("list", this.list);

        return res;
      });
    }
  },
  components: {}
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/mixins";

.el-button {
  padding: 5px 15px !important;
}
table {
  border-collapse: collapse;
}
.btn {
  cursor: pointer;
}
.button {
  button {
    line-height: 30px;
    height: 30px;
    padding: 0 10px;
  }
}
.title {
  width: 100%;
  line-height: 30px;
  margin-bottom: 20px;
  @include row;
  @include align-center;
  .active1 {
    border-bottom: 2px solid #fe6925 !important;

    color: #fe6925;
  }
  span {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    padding: 0 20px;
  }
}
.order {
  margin-top: 10px;
}
.order-list {
  min-height: 700px;

  .order-table-head {
    width: 100%;

    tr {
      background: #eee;
    }

    th {
      box-sizing: border-box;
      font-size: 14px;
      padding: 10px;
    }
  }
  .first-row {
    background: #f5f5f5;
  }
  .order-table-content {
    width: 100%;

    thead {
      tr {
        background: #dedcdc;
      }

      th {
        box-sizing: border-box;

        text-align: left;
        padding: 10px;
      }

      i {
        font-size: 16px;
      }
    }

    tbody {
      tr td .first-row {
        // background: #;f5f5f5
        td {
          text-align: left;
        }
      }

      td {
        box-sizing: border-box;

        text-align: center;
        padding: 5px 10px;
      }
    }
  }

  .dl1 {
    line-height: 40px;
    height: 40px;
    border-bottom: 1px solid #dedcdc;
    background: #dedcdc;
  }
  .dl2 {
    padding: 0 10px !important;
    background: #f5f5f5;
  }
  dl {
    width: 100%;
    line-height: 30px;
    padding: 0 10px;

    @include space-between;
  }
  .graybg {
    background: #eee;
    padding: 10px;
  }
  .get_order_product {
    padding: 10px;
  }
  .item {
    .shop-name {
      padding: 0.2rem;
    }
    @include space-between;
    .item-img-wraper {
      width: 80px;
      height: 80px;
      margin-right: 10px;
      display: inline-block;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-info {
      text-align: left;
      p {
        margin-bottom: 10px;
      }
      flex: 1;
      .about {
        color: #999;
      }
    }
  }

  .td1 {
    border-right: 1px solid #dedcdc;
    .item_id {
      padding: 10px;
    }
    width: 70%;
    ul {
      width: 100%;
      @include space-between;
    }
    li:nth-child(1) {
      text-align: left;
      width: 40%;
      text-align: center;
    }
    li:nth-child(2) {
      width: 20%;
      text-align: center;
    }
    li:nth-child(3) {
      width: 20%;
      text-align: center;
    }
    li:nth-child(4) {
      width: 20%;
      text-align: center;
      p {
        margin-bottom: 5px;
      }
    }
  }
  .td2 {
    p {
      cursor: pointer;
      line-height: 25px;
    }
    width: 30%;

    ul {
      width: 100%;
      @include space-between;
      padding: 10px;
    }
    li:nth-child(1) {
      width: 30%;
      text-align: center;
    }
    li:nth-child(2) {
      p {
        margin-bottom: 5px;
      }
      width: 30%;
      text-align: center;
    }
    li:nth-child(3) {
      width: 30%;
      text-align: center;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 20px;
      }
    }
  }
}
</style>
